import React from 'react'
import { Helmet } from 'react-helmet'

import LayoutStorelocator from '../../layout/storelocator'

import Locator from '../../components/locator'

import Retailer from '../../svg/retailer.svg'

import Puzzle from '../../svg/storelocator/puzzle.svg'
import Chart from '../../svg/storelocator/chart.svg'
import Clock from '../../svg/storelocator/clock.svg'
import Sync from '../../svg/storelocator/sync.svg'
import Map from '../../svg/storelocator/map.svg'

const Page = () => (
        <LayoutStorelocator language="de">

            <Helmet>
                <title>Zupr - Filialfinder</title>
                <meta name="description" content="Mit Zupr zeigen Sie Verbrauchern online, wo Produkte in lokalen, physischen Geschäften erhältlich sind." />
            </Helmet>

            <section className="text-left image-right divider">
                <div className="inner">

                    <div className="text">
                        <h2>Entdecke den Zupr Filialfinder</h2>
                        <p>Zupr verfügt über eine neue Version einer Filialfindersoftware. Klassische Filialfinder fordern, dass Sie ihre Verkaufs- und Standortsdaten selbst pflegen. Mit der Software von Zupr arbeiten Sie mit den Einzelhändlern zusammen, um die lokale Verfügbarkeit ihrer Produkte zugänglich zu machen. Wenn zum Beispiel ein Geschäft seine Öffnungszeiten oder Produktsortiment ändert, werden diese Änderungen direkt sichtbar auf der Webseite. Das spart nicht nur Zeit, sondern stellt auch sicher, dass Kunden immer die aktuellen Informationen zur Verfügung haben.</p>
                        <a className="btn btn-demo" href="mailto:sales@zupr.io?subject=Filialfinder demo">Fordere eine kostenlose Testversion an</a>
                    </div>

                    <div className="image">
                        <Retailer />
                    </div>

                </div>
            </section>

            <section className="text-center image-bottom divider">
                <div className="inner">

                    <div className="text">
                        <h2>Es gibt noch mehr: Vom Filialfinder zum Produktfinder</h2>
                        <p>Die meisten Filialfinder zeigen nur Verkaufsstandorte auf Markenebene, obwohl Kunden eher daran interessiert sind, wo sie ein bestimmtes Produkt kaufen können. Zupr arbeitet indessen auf der Produktebene, so dass angezeigt wird, in welchen Geschäften einzelne Produkte auf Lager sind. So erhalten Verbraucher alle Informationen, die sie suchen!</p>
                        <Locator storeLocatorLabel="Filialfinder" productLocatorLabel="Produktfinder" />
                    </div>

                </div>
            </section>

            <section className="text-center">
                <div className="inner">

                    <div className="text">
                        <h2>Arbeiten Sie zusammen mit ihren lokalen Einzelhändlern</h2>
                        <p>Durch die digitale Übermittlung ihrer Produktinformationen und Produktbilder an Zupr, müssen Einzelhändler dies nicht mehr selber pflegen. Auf diese Weise behalten sie die Kontrolle über ihre Markenidentität und die Einzelhändler können ihr Produktsortiment und Bestand leichter kommunizieren. Die ZuprCharger App ermöglicht selbst den kleinsten Einzelhändlern teilzunehmen.</p>
                    </div>

                </div>
            </section>

            <section className="text-center grey">
                <div className="inner">

                    <div className="text">
                        <h2>Vorteile von Zupr</h2>
                        <div className="usps">
                            <div className="block with-icon">
                                <h3><Puzzle />Einfache Integration</h3>
                                <p>Sie können den Zupr Filialfinder leicht auf ihrer Webseite integrieren. Es ist möglich, den Filialfinder an ihre Firmenidentität und Marke anzupassen.</p>
                            </div>
                            <div className="block with-icon">
                                <h3><Chart />Analysen/Statistiken</h3>
                                <p>Ein benutzerfreundliches Dashboard liefert hilfreiche Echtzeitdaten. Heat Maps bieten beispielsweise einen direkten Einblick in die lokale Nachfrage ihrer Produkte.</p>
                            </div>
                            <div className="block with-icon">
                                <h3><Clock />Beginne sofort</h3>
                                <p>Sobald ihr Konto erstellt wurde, können sie loslegen. In ihrer persönlichen Administrationsumgebung können sie Produkte hinzufügen und direkten Kontakt zu Einzelhändlern aufnehmen, welche ihre Daten über Bestand und Produktsortiment übermitteln können.</p>
                            </div>
                            <div className="block with-icon">
                                <h3><Sync />Immer Up-to-date</h3>
                                <p>Da Einzelhändler ihre Daten selbst verwalten, können Kunden stets die konkreten Öffnungszeiten und Kontaktinformationen einsehen. Auch Information über den Lagerbestand sind sichtbar, was für den Kunden von großer Bedeutung ist.</p>
                            </div>
                            <div className="block with-icon">
                                <h3><Map />Routenplaner</h3>
                                <p>Kunden können sich eine Route zum nächstgelegenen Geschäft, welche ihre Produkte verkaufen, anzeigen lassen und können diese Ergebnisse auch nach Preis und Verfügbarkeit sortieren.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="text-center divider">
                <div className="inner">

                    <div className="text">
                        <h2>Preise</h2>
                        <p>Für mehr Informationen über die Preise, treten Sie bitte in Kontakt mit uns über <a href="mailto:sales@zupr.io">sales@zupr.io</a>.</p>
                    </div>

                </div>
            </section>

            <section className="contact-boost">
                <div className="inner">
                    <h2>Fragen?</h2>
                    <p>Bei Fragen wenden Sie sich bitte an das Zupr-Team.</p>
                    <p>
                        <a href="mailto:support@zupr.nl?subject=Frage zu Zupr" className="email">support@zupr.nl</a>
                        <a href="tel:+31854011306" className="telephone">+31 (0)85 40 11 306</a>
                    </p>
                </div>
            </section>

    </LayoutStorelocator>
)

export default Page
